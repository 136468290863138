import { gql } from "@apollo/client"

const GET_CART = gql`
  query GET_CART {
    cart {
      isEmpty
      availableShippingMethods {
        rates {
          methodId
          label
          instanceId
          id
          cost
        }
      }
      chosenShippingMethods
      appliedCoupons {
        code
        discountAmount
        discountTax
      }
      contents {
        nodes {
          key
          product {
            node {
              id
              databaseId
              name
              type
              onSale
              slug
              image {
                id
                sourceUrl
                srcSet
                altText
                title
              }
              ... on SimpleProduct {
                price
              }
            }
          }
          quantity
          total
          subtotal
          subtotalTax
        }
      }
      subtotal
      subtotalTax
      shippingTax
      shippingTotal
      total
      totalTax
      feeTax
      feeTotal
      discountTax
      discountTotal
    }
  }
`

export default GET_CART
